import React, { useLayoutEffect, useState } from "react"
import { Link } from "gatsby"
type Props = {}

function Header({}: Props) {
  const [menuActive, toggleMenu] = useState(false)
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      toggleMenu(true)
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [])

  return (
    <div className="w-full shadow-sm md:fixed md:left-0 md:top-0">
      <div className="grid grid-cols-2 items-center">
        <div className="h-8 w-8 rounded-md ml-8 bg-black grow-0 my-4 cursor-pointer"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 justify-self-end mr-8 cursor-pointer md:hidden"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          onClick={() => toggleMenu(!menuActive)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>

        <nav
          className={`grid text-center col-span-2 bg-stone-100 md:bg-inherit md:grid-flow-col py-4 ${
            !menuActive ? "hidden" : ""
          } md:col-span-1 md:justify-self-end md:mr-8`}
        >
          <Link to="/" className="my-2 md:mx-8">
            Work
          </Link>
          <Link to="/" className="my-2 md:mx-8">
            About
          </Link>
        </nav>
      </div>
    </div>
  )
}

export default Header
