import * as React from "react"
import "../styles/global.css"
import Footer from "./footer"
import Header from "./header"

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen bg-stone-50 font-roboto text-stone-900">
      <Header />
      <main className="flex-auto">{children}</main>
      <Footer />
    </div>
  )
}
