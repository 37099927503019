import * as React from "react"
import type { PageProps } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = ({}: PageProps) => {
  return (
    <Layout>
      <h1>Page Not Found</h1>
    </Layout>
  )
}

export default NotFoundPage
