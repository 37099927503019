import React from "react"

type Props = {}

export default function Footer({}: Props) {
  return (
    <div className="text-center py-7 snap-end">
      Designed & Built by Marwan Bardaji
    </div>
  )
}
